<template>
  <section class="about">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper auth p-0 theme-two">
          <div class="row d-flex align-items-stretch">
            <div class="col-md-4 banner-section d-none d-md-flex align-items-stretch justify-content-center">
              <div class="slide-content bg-2"> </div>
            </div>
            <div class="col-12 col-md-8 h-100 bg-white">
              <div class="auto-form-wrapper d-flex align-items-center justify-content-center flex-column">
                <navbar-pages></navbar-pages>

                <h1 class="w-100 mb-4 text-left text-center-responsive">Funcionalidades del Observatorio</h1>
                <p>
                    La plataforma opera, en primer lugar, como un repositorio de información energética de edificios de 
                    propiedad municipal, posibilitando su explotación. Esta información está basada principalmente en la 
                    contenida y sistematizada en la elaboración de certificados de eficiencia energética de edificios, y 
                    contiene datos descriptivos reales de los mismos, así como las estimaciones en consumo y emisiones que 
                    de ellos derivan. Finalmente, también contienen sugerencias de medidas de mejora, que a su vez implican 
                    unas estimaciones de ahorro energético y en emisiones, y una valoración económica.
                </p>
                <p>
                    Este modelo de datos para el almacenamiento de información relevante sobre la eficiencia energética en 
                    edificios se explota a su vez en línea con las necesidades prácticas de los potenciales usuarios/as. 
                    Esta explotación está basada en la comparación de edificios similares en términos principales de localización 
                    geográfica (zona climática) y uso, así como en aspectos secundarios: edad, configuración.
                </p>
                <p>
                    La plataforma relaciona la aportación de información con la explotación de la misma, a nivel de usuario/a. 
                    En este sentido, establece dos perfiles de acceso, uno para visitantes y otro para usuarios registrados. 
                    Mientras que para los primeros sólo se pueden acceder a descripciones y comparaciones básicas de los edificios 
                    de la base de datos, con arreglo a filtros sencillos; para los segundos, al poder dar de altas edificios propios, 
                    las comparaciones con edificios similares se realizan automáticamente de acuerdo con las características del 
                    edificio dado de alta.
                </p>
                <p>
                    Una funcionalidad clave es la de estimar mejoras en términos de calificación energética, y éstas en cuanto 
                    a letra, ya que es habitual que las ayudas públicas usen este baremo para evaluar las reformas de edificios.
                </p>

                <footer-pages></footer-pages>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import NavbarPages from '@/components/NavbarPages'
import FooterPages from '@/components/FooterPages'

export default {
    name: "Functionalities",

    components: {
        NavbarPages,
        FooterPages
    }
}
</script>

<style scoped>

</style>
